.hidden {
  display: none !important;
  visibility: hidden;
}

.pane--active .m-tab__label,
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.pane--active .focusable.m-tab__label:active, .pane--active .focusable.m-tab__label:focus,
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.compare__wrapper:before, .compare:before, .accordion__content:before, .m-tab__nav:before, .l-container--large:before, .l-container:before, .compare__wrapper:after, .compare:after, .accordion__content:after, .m-tab__nav:after, .l-container--large:after, .l-container:after,
.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
.compare__wrapper:after, .compare:after, .accordion__content:after, .m-tab__nav:after, .l-container--large:after, .l-container:after,
.clearfix:after {
  clear: both;
}

@font-face {
  font-family: "DIN";
  src: url("../fonts/DIN/DINWeb.eot");
  src: url("../fonts/DIN/DINWeb.eot?#iefix") format("embedded-opentype"), url("../fonts/DIN/DINWeb.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "DIN";
  src: url("../fonts/DIN/DINWeb-Light.eot");
  src: url("../fonts/DIN/DINWeb-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/DIN/DINWeb-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "DIN";
  src: url("../fonts/DIN/DINWeb-Bold.eot");
  src: url("../fonts/DIN/DINWeb-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/DIN/DINWeb-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Strada";
  src: url("../fonts/Strada/StradaWebPro.eot");
  src: url("../fonts/Strada/StradaWebPro.eot?#iefix") format("embedded-opentype"), url("../fonts/Strada/StradaWebPro.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

:root,
html {
  min-height: 100%;
  box-sizing: border-box;
}

body {
  background-color: #fff;
  color: #222;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media all and (min-width: 46.25em) {
  body.js-cookie-note--is-visible {
    padding-bottom: 13.4rem;
  }
}

h1, .m-tab__headline, h2, h3, h4, h5, h6,
p, blockquote, address,
dl, ul, ol,
table,
fieldset,
figure,
pre, code {
  margin: 0 0 2rem;
}

a {
  color: #36A9E0;
  text-decoration: none;
}
a:hover, a:focus {
  color: #F0AE2E;
  text-decoration: underline;
}
.richtext a {
  font-weight: 600;
}
a img {
  border: 0;
}

button,
input[type=submit],
input[type=reset],
input[type=button],
input[type=search] {
  -webkit-appearance: none;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

svg {
  height: auto;
  max-width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

abbr {
  cursor: help;
}

dt,
dd {
  display: block;
  margin-top: 0.5rem;
}

dt {
  padding: 0;
  font-weight: bold;
}
dt:first-child {
  margin-top: 0;
}

dd {
  margin: 0;
}

hr {
  background-color: #ccc;
  border: 0;
  font-size: 1px;
  height: 1px;
  line-height: 1;
  margin: 2rem 0;
  display: block;
  clear: both;
}

table {
  border-collapse: collapse;
  border: 0;
  font-size: 1.2rem;
  vertical-align: top;
  width: 100%;
  table-layout: fixed;
}
table p:last-child {
  margin-bottom: 0;
}

tr {
  border-top: 1px solid #ccc;
}
tr:first-child {
  border-top-color: #222;
}
thead tr {
  border-top: 0;
}

th,
td {
  border: 0;
  padding: 0.5rem;
  text-align: left;
  vertical-align: top;
}
th:first-child,
td:first-child {
  padding-left: 0;
}
th:last-child,
td:last-child {
  padding-right: 0;
}
tr:last-child th,
tr:last-child td {
  padding-bottom: 0.5rem;
}

th {
  font-weight: 700;
  vertical-align: middle;
  white-space: nowrap;
}

tr:first-child td {
  padding-top: 0.5rem;
}

embed {
  height: auto;
  max-width: 100%;
}

code,
pre {
  font-family: Monaco, Consolas, monospace;
  background-color: rgba(34, 34, 34, 0.1);
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  font-size: 1.2rem;
  overflow: scroll;
}

code {
  padding: 0.1rem 0.5rem;
}

pre {
  padding: 1rem 1.5rem;
}

address {
  font-style: normal;
}

dfn,
samp {
  font-family: Monaco, Consolas, monospace;
}

:root,
html {
  font-size: 62.5%;
  line-height: 1.5;
  text-size-adjust: none;
}

body {
  font-family: "Strada", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1.4;
}

h1, .m-tab__headline, h2, h3, h4, h5, h6 {
  font-family: "DIN", Montserrat, Helvetica, Arial, sans-serif;
  line-height: 1.2;
}

h1, .m-tab__headline {
  font-size: 3rem;
  font-weight: 400;
}
@media all and (min-width: 46.25em) {
  h1, .m-tab__headline {
    font-size: 5rem;
  }
}

h2 {
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 1.2rem;
}

p {
  font-weight: 300;
}
p:empty {
  display: none;
}

strong {
  font-weight: 700;
}

::selection {
  background-color: #9dc530;
  color: #fff;
}

.document-footer a.download {
  position: relative;
  background: rgba(54, 169, 224, 0.9);
  border-radius: 50%;
  border: 2px solid #36A9E0;
  box-shadow: 0 0 3px 0 rgba(170, 170, 170, 0.5);
  color: #fff;
  display: block;
  font-size: 1.6rem;
  height: 125px;
  line-height: 1;
  margin-top: -60px;
  padding: 28px 0 1em;
  position: absolute;
  right: 15px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transform: rotate(10deg);
  transform-origin: 50%;
  transition: transform 0.1s 0s ease;
  vertical-align: middle;
  width: 125px;
  z-index: 42;
}
.document-footer a.download:before {
  height: 37px;
  background-image: url("../img/svg-sprite.svg");
  background-position: 0px -120px;
  width: 37px;
  background-size: 150px 472px;
  background-repeat: no-repeat;
  bottom: 12px;
  content: "";
  display: block;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: auto;
}
.document-footer a.download:focus, .document-footer a.download:hover {
  transform: rotate(10deg) scale(1.1);
}
.document-footer a.download div {
  margin-top: 0.5rem;
  font-size: 1.4rem;
  white-space: nowrap;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  font-weight: 400;
}

form {
  font-size: 1.2rem;
}

fieldset {
  border: 0;
  padding: 0;
}

legend {
  display: block;
  font-size: 1.6rem;
  text-transform: uppercase;
}

label {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
}

button, input, select, textarea {
  vertical-align: middle;
}

input,
select,
textarea {
  font-size: 1.2rem;
  line-height: 1.4;
}

[type=color],
[type=date],
[type=datetime],
[type=datetime-local],
[type=email],
[type=file],
[type=month],
[type=number],
[type=password],
[type=phone],
[type=range],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
select,
textarea {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
}

textarea {
  min-height: 5em;
  height: 12em;
}

select[multiple],
select[size] {
  height: auto;
}

.l-container--large, .l-container {
  clear: both;
  margin: 0 auto;
}

.l-column {
  position: relative;
  padding: 0 15px;
}
.l-column:before, .l-column:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
.l-column:after {
  clear: both;
}
@media all and (min-width: 46.25em) {
  .l-column {
    float: left;
  }
}

.l-container {
  max-width: 1000px;
}

.l-container--large {
  max-width: 1400px;
}

.l-column--tiny {
  position: relative;
  padding: 0 15px;
}
@media all and (min-width: 20em) {
  .l-column--tiny {
    float: left;
    width: 50%;
  }
}
@media all and (min-width: 46.25em) {
  .l-column--tiny {
    width: 25%;
  }
}
.l-column--tiny:nth-of-type(1), .l-column--tiny:nth-of-type(2) {
  margin-bottom: 2rem;
}
@media all and (min-width: 46.25em) {
  .l-column--tiny:nth-of-type(1), .l-column--tiny:nth-of-type(2) {
    margin-bottom: 0;
  }
}
@media all and (min-width: 20em) {
  .l-column--tiny:nth-of-type(2n + 1) {
    clear: left;
  }
}
@media all and (min-width: 46.25em) {
  .l-column--tiny:nth-of-type(2n + 1) {
    clear: none;
  }
}
@media all and (min-width: 46.25em) {
  .l-column--tiny:nth-of-type(4n + 1) {
    clear: left;
  }
}

@media all and (min-width: 46.25em) {
  .l-column--small {
    width: 33.3333333333%;
  }
}

@media all and (min-width: 46.25em) {
  .l-column--medium {
    width: 50%;
  }
}
.l-column--medium:nth-child(1), .l-column--medium:nth-child(3) {
  clear: left;
}
@media all and (min-width: 46.25em) {
  .l-column--medium:nth-child(1), .l-column--medium:nth-child(3) {
    clear: none;
  }
}

@media all and (min-width: 46.25em) {
  .l-column--large {
    width: 66.6666666667%;
  }
}

.l-column--full {
  position: relative;
  padding: 0 15px;
}
@media all and (min-width: 46.25em) {
  .l-column--full {
    float: none;
  }
}
.l-container--large .l-column--full {
  padding: 0;
}
@media all and (min-width: 46.25em) {
  .teaser-list--extraordinary .l-column--full {
    padding: 0;
  }
}

body.widget {
  background: #36A9E0;
  overflow: hidden;
}

.document-footer__main, .document-additional, .document-complementary, .document-content, .document-section {
  padding: 0;
}

body.article > .document-header {
  height: 11rem;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  border-top: 0.5rem solid #0080C9;
}
body.article > .document-header h1, body.article > .document-header .m-tab__headline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
  text-align: center;
  font-size: 1.2rem;
  padding: 0.5rem 0 1.2rem;
}
@media all and (min-width: 33.75em) {
  body.article > .document-header h1, body.article > .document-header .m-tab__headline {
    font-size: 1.6rem;
    padding-bottom: 1.2rem;
  }
}
@media all and (min-width: 46.25em) {
  body.article > .document-header h1, body.article > .document-header .m-tab__headline {
    padding: 0 3rem 0 13rem;
    text-align: center;
    font-size: 1.8rem;
    line-height: 10rem;
    position: relative;
  }
}
@media all and (min-width: 61.25em) {
  body.article > .document-header h1, body.article > .document-header .m-tab__headline {
    font-size: 2.4rem;
    padding-right: 5rem;
  }
}
@media all and (min-width: 75em) {
  body.article > .document-header h1, body.article > .document-header .m-tab__headline {
    font-size: 3rem;
    padding-right: 6rem;
  }
}

body.microsite > .document-header {
  position: fixed;
  top: -11rem;
  left: 0;
  width: 100%;
  height: 11rem;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  border-top: 0.5rem solid #0080C9;
}
body.microsite > .document-header h1, body.microsite > .document-header .m-tab__headline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
  text-align: center;
  font-size: 1.2rem;
  padding: 0.5rem 0 1.2rem;
}
@media all and (min-width: 33.75em) {
  body.microsite > .document-header h1, body.microsite > .document-header .m-tab__headline {
    font-size: 1.6rem;
    padding-bottom: 1.2rem;
  }
}
@media all and (min-width: 46.25em) {
  body.microsite > .document-header h1, body.microsite > .document-header .m-tab__headline {
    padding: 0 3rem 0 13rem;
    text-align: center;
    font-size: 1.8rem;
    line-height: 10rem;
  }
}
@media all and (min-width: 61.25em) {
  body.microsite > .document-header h1, body.microsite > .document-header .m-tab__headline {
    font-size: 2.4rem;
    padding-right: 5rem;
  }
}
@media all and (min-width: 75em) {
  body.microsite > .document-header h1, body.microsite > .document-header .m-tab__headline {
    font-size: 3rem;
    padding-right: 6rem;
  }
}

body.microsite.header--hide > .document-header,
body.microsite.area--main > .document-header {
  transform: translateY(-100%);
}

body.widget > .document-header {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  height: 5rem;
  padding: 1.2rem;
  position: relative;
  text-align: center;
}
@media (min-height: 300px) {
  body.widget > .document-header {
    height: 8rem;
  }
}
body.widget > .document-header h1, body.widget > .document-header .m-tab__headline {
  margin-bottom: 0;
  font-size: 1.2rem;
}
@media all and (min-width: 33.75em) {
  body.widget > .document-header h1, body.widget > .document-header .m-tab__headline {
    font-size: 1.6rem;
  }
}
@media all and (min-width: 46.25em) {
  body.widget > .document-header h1, body.widget > .document-header .m-tab__headline {
    font-size: 1.8rem;
  }
}
@media all and (min-width: 61.25em) {
  body.widget > .document-header h1, body.widget > .document-header .m-tab__headline {
    font-size: 2.4rem;
  }
}
@media all and (min-width: 75em) {
  body.widget > .document-header h1, body.widget > .document-header .m-tab__headline {
    font-size: 3rem;
  }
}
body.widget > .document-header h1 a, body.widget > .document-header .m-tab__headline a {
  color: inherit;
}

.document-section__title {
  position: relative;
  display: block;
  background-color: #F0AE2E;
  padding: 5rem 0;
  text-align: center;
  font-weight: normal;
}
.document-section__title:before {
  display: block;
  content: "";
  height: 0;
  position: absolute;
  width: 0;
  z-index: 10;
  border-left: 3.2rem solid transparent;
  border-right: 3.2rem solid transparent;
  border-top: 1.6rem solid #F0AE2E;
  bottom: -1.6rem;
  left: calc(50% - 3.2rem);
}

body.widget > .document-main {
  top: 6rem;
  left: 0;
  position: absolute;
  width: 100%;
}
@media (min-height: 350px) {
  body.widget > .document-main {
    top: 11rem;
  }
}

body.article > .document-main {
  padding-top: 4rem;
}

.m-tab__nav {
  display: flex;
  font-size: 1.2rem;
  font-weight: 700;
  list-style: none;
  padding: 0;
  margin: 6rem auto 6rem;
}
@media all and (min-width: 46.25em) {
  .m-tab__nav {
    font-size: 1.6rem;
  }
}
@media all and (min-width: 61.25em) {
  .m-tab__nav {
    font-size: 2rem;
  }
}

.m-tab__nav__item {
  background-color: #36A9E0;
  color: #fff;
  flex: 1 1 auto;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: width 0.3s 0s ease;
  width: 50%;
}
@media all and (min-width: 46.25em) {
  .m-tab__nav__item {
    width: 30%;
  }
}
.m-tab__nav__item.tab--active {
  position: relative;
  background-color: #F0AE2E;
}
.m-tab__nav__item.tab--active:before {
  display: block;
  content: "";
  height: 0;
  position: absolute;
  width: 0;
  z-index: 10;
  border-left: 3.2rem solid transparent;
  border-right: 3.2rem solid transparent;
  border-top: 1.6rem solid #F0AE2E;
  bottom: -1.6rem;
  left: calc(50% - 3.2rem);
}
@media all and (min-width: 46.25em) {
  .m-tab__nav__item.tab--active {
    width: 70%;
  }
}
.m-tab__nav__item a {
  color: inherit;
  display: block;
  padding: 2rem 0.5rem;
  text-decoration: none;
}
@media all and (min-width: 33.75em) {
  .m-tab__nav__item a {
    padding: 2rem;
  }
}

.m-tab--initialized .m-tab {
  display: none;
}
.m-tab--initialized .m-tab.tab--active, .m-tab--initialized .m-tab.pane--active {
  padding: 2rem 0;
  display: block;
}

.document-section__title-extension {
  color: #36A9E0;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
  margin-left: 2rem;
}
.document-footer {
  border-bottom: 0.5rem solid #0080C9;
}
body.widget > .document-footer {
  border: 0;
  bottom: 8px;
  position: absolute;
  right: 8px;
}
@media (min-height: 350px) {
  body.widget > .document-footer {
    bottom: 12px;
    right: 12px;
  }
}

.theme--light {
  background-color: #fff;
}

.theme--dark {
  background-color: #222;
  color: #fff;
}

.theme--image {
  background-size: cover;
  background-position: 75% 50%;
  color: #fff;
  text-align: center;
  padding: 0;
  position: relative;
  transition: background-position 0.3s 0s ease;
}
.theme--image:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #36A9E0;
  mix-blend-mode: multiply;
}
body.area--main .theme--image {
  background-position: 75% 0%;
}

.theme--grey {
  background-color: #f0f0f0;
}

.theme--blue {
  background-color: #36A9E0;
  color: #fff;
}

html.kiosk .document-header .logo img {
  display: none;
}
html.kiosk .document-header .fullscreen-message {
  display: none;
}
@media all and (min-width: 61.25em) {
  html.kiosk {
    background: #36A9E0;
    font-size: min(0.6vw, 1vh);
  }
  html.kiosk .microsite > .document-header {
    overflow: visible;
    transform: translateY(100%) !important;
    z-index: 20;
  }
  html.kiosk .microsite > .document-header h1,
  html.kiosk .microsite > .document-header .m-tab__headline {
    font-size: 3rem;
  }
  html.kiosk .microsite > .document-header .fullscreen-message {
    animation: fullscreen-message 5s linear both;
    background: #ffffff;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.3), 0 0 11.5rem 77rem rgba(0, 0, 0, 0.2);
    color: #888888;
    display: block;
    font-size: 3rem;
    font-weight: bold;
    left: 50%;
    line-height: 1;
    max-width: 60%;
    padding: 2.8rem 3rem 2rem;
    pointer-events: none;
    position: fixed;
    top: 50vh;
    transform: translate(-50%, -50%);
    width: fit-content;
  }
  html.kiosk .document-header .logo {
    height: auto;
    left: 1vw;
    top: 1.5rem;
    width: 12rem;
  }
  html.kiosk .document-header .logo img {
    display: block;
    height: auto;
    width: 100%;
  }
  html.kiosk .logo:before {
    display: none;
  }
  html.kiosk .counter__scroll-button {
    display: none;
  }
  html.kiosk .l-container {
    max-width: none;
  }
  html.kiosk .counter[stage] {
    height: auto !important;
    margin-top: 7rem;
    min-height: auto;
  }
  html.kiosk .counter[minime] {
    margin-bottom: 3vw;
    max-width: 32vw;
    opacity: 1;
    transform: none !important;
  }
  html.kiosk .theme--image {
    height: 100vh !important;
    padding-top: 10rem;
  }
  html.kiosk .document-section__footer > .l-container {
    display: flex;
    flex-flow: column;
    height: min(100vh - 49rem,40vw);
  }
  html.kiosk .document-section__footer > .l-container > .l-column.l-column--full {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: center;
  }
  html.kiosk .document-section__footer > .l-container #info.l-column--large {
    flex-grow: 0;
  }
  html.kiosk .document-footer {
    display: none;
  }
  html.kiosk.kiosk--reduced #info {
    display: none;
  }
  html.kiosk.kiosk--reduced #info-reduced {
    font-size: 2.7rem;
  }
  html.kiosk.kiosk--reduced .counter[stage] {
    margin-top: 12rem;
  }
  html.kiosk.kiosk--reduced .counter__sum {
    font-size: 9rem;
  }
  html.kiosk.kiosk--reduced .counter__sum#sumTotal {
    margin-bottom: 1rem;
  }
  html.kiosk.kiosk--reduced .counter__copy {
    font-size: 2.7rem;
  }
  html.kiosk.kiosk--reduced .counter[minime] {
    max-width: none;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  html.kiosk.kiosk--reduced .counter[minime] .counter__sum {
    margin-bottom: -1rem;
  }
  html.kiosk.kiosk--reduced .counter[minime] .counter__item + .counter__item {
    margin-top: 1rem;
  }
  html.kiosk.kiosk--reduced .counter__header .counter__copy {
    margin-bottom: 0;
  }
}

@keyframes fullscreen-message {
  0%, 90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
body.article {
  background-color: #f0f0f0;
}
body.article .document-header {
  background-color: #fff;
}

.logo {
  position: relative;
  display: block;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -58px;
  width: 116px;
  height: 44px;
  text-indent: -200vw;
  overflow: hidden;
  z-index: 42;
}
.logo:before {
  height: 72px;
  background-image: url("../img/svg-sprite.svg");
  background-position: 0px -290px;
  width: 114.2px;
  background-size: 150px 472px;
  background-repeat: no-repeat;
  bottom: auto;
  content: "";
  display: block;
  left: 0;
  margin: auto;
  position: absolute;
  right: auto;
  top: 0;
}
@media all and (min-width: 46.25em) {
  .logo {
    left: 15px;
    height: 72px;
    margin-left: 0;
  }
}

body.widget .logo {
  position: relative;
  position: static;
  margin-left: 0;
  left: 0;
  height: 38px;
  transform: scale(0.5);
  transform-origin: bottom right;
}
body.widget .logo:before {
  height: 37.8px;
  background-image: url("../img/svg-sprite.svg");
  background-position: 0px -434px;
  width: 114px;
  background-size: 150px 472px;
  background-repeat: no-repeat;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}
@media (min-height: 350px) {
  body.widget .logo {
    transform: scale(0.75);
  }
}

.counter {
  padding: 2rem 0;
}
.counter * {
  transition: opacity 0.7s ease;
  opacity: 1;
}
.loading .counter * {
  opacity: 0;
}
.counter[stage] {
  min-height: 480px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  padding-left: 15px;
  padding-right: 15px;
}
.counter[minime] {
  position: relative;
  background-color: #F0AE2E;
  max-width: 500px;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.1s 0s ease, transform 0.3s 0s ease;
}
.counter[minime]:before {
  display: block;
  content: "";
  height: 0;
  position: absolute;
  width: 0;
  z-index: 10;
  border-left: 3.2rem solid transparent;
  border-right: 3.2rem solid transparent;
  border-top: 1.6rem solid #F0AE2E;
  bottom: -1.6rem;
  left: calc(50% - 3.2rem);
}
.counter[minime]::before {
  bottom: auto;
  top: 100%;
}
body.area--main .counter[minime] {
  opacity: 1;
  transform: translateY(-110px);
}
.counter[minime].is-fixed {
  background-color: #36A9E0;
  max-width: none;
  left: 0;
  padding: 1.2rem 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1003;
}
@media all and (min-width: 75em) {
  .counter[minime].is-fixed {
    left: auto;
    top: 30%;
  }
}
.counter[minime].is-fixed::before {
  display: none;
}
body.area--main .counter[minime].is-fixed {
  transform: translateY(0);
}

.counter__header {
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.counter__item {
  margin-bottom: 0;
  padding: 0 1.2rem;
}
@media all and (min-width: 33.75em) {
  .counter__item {
    padding: 0 2rem;
  }
}

.is-fixed .counter__item--per-minute {
  display: none;
}

.counter__title {
  font-size: 2.3rem;
}
@media all and (min-width: 33.75em) {
  .counter__title {
    font-size: 3rem;
  }
}
body.widget .counter__title {
  font-size: 1.2rem;
  text-transform: none;
  margin: 0;
}
@media all and (min-width: 33.75em) {
  body.widget .counter__title {
    font-size: 1.6rem;
  }
}

.counter__title + .counter__sum {
  font-size: 2.5rem;
}
@media all and (min-width: 33.75em) {
  .counter__title + .counter__sum {
    font-size: 4.4rem;
  }
}
@media all and (min-width: 46.25em) {
  .counter__title + .counter__sum {
    font-size: 5rem;
  }
}
@media all and (min-width: 61.25em) {
  .counter__title + .counter__sum {
    font-size: 8rem;
  }
}
@media all and (min-width: 75em) {
  .counter__title + .counter__sum {
    font-size: 9rem;
  }
}

.counter__sum {
  font-size: 1.6rem;
  white-space: nowrap;
}
@media all and (min-width: 20em) {
  .counter__sum {
    font-size: 3rem;
  }
}
@media all and (min-width: 33.75em) {
  .counter__sum {
    font-size: 5rem;
  }
}
@media all and (min-width: 20em) {
  .is-fixed .counter__sum {
    font-size: 2rem;
  }
}
@media all and (min-width: 75em) {
  .is-fixed .counter__sum {
    font-size: 3rem;
  }
}
.counter__sum .value {
  transform: translate3d(0, 0, 0);
}
.counter__sum .currency {
  font-size: 80%;
  text-transform: uppercase;
}
body.microsite .counter__sum#sumTotal {
  margin-bottom: 2rem;
}
@media all and (min-width: 61.25em) {
  body.microsite .counter__sum#sumTotal {
    margin-bottom: 2rem;
  }
}
body.widget .counter__sum {
  line-height: 1;
  margin: 0.5rem 0;
}
@media all and (min-width: 20em) {
  body.widget .counter__sum {
    font-size: 3rem;
  }
}
@media all and (min-width: 46.25em) {
  body.widget .counter__sum {
    font-size: 5rem;
  }
}

@keyframes arrow-bounce {
  from {
    transform: translateY(-12%);
  }
  to {
    transform: translateY(21%);
  }
}
.counter__scroll-button {
  background: transparent;
  display: block;
  cursor: pointer;
  font-size: 1em;
  border: 0;
  outline: 0;
  animation: arrow-bounce infinite 0.6s alternate ease;
  position: absolute;
  bottom: 6rem;
  left: 50%;
  width: 150px;
  margin-left: -75px;
  text-align: left;
  color: #fff;
  line-height: 60px;
  height: 60px;
  white-space: nowrap;
  text-indent: 200%;
  overflow: hidden;
  padding: 0;
  z-index: 0;
  transition: opacity 0.1s 0s ease;
}
.counter__scroll-button:before {
  height: 60px;
  background-image: url("../img/svg-sprite.svg");
  background-position: 0px 0px;
  width: 150px;
  background-size: 150px 472px;
  background-repeat: no-repeat;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.counter__scroll-button:focus, .counter__scroll-button:hover {
  opacity: 0.7;
}
body.area--main .counter__scroll-button {
  opacity: 0;
}

.counter__scroll-button__icon {
  position: relative;
  overflow: hidden;
  position: absolute;
  bottom: -40px;
  left: 50%;
  margin-left: -30px;
  width: 60px;
  height: 5rem;
  box-shadow: inset 0 5px 5px -4px rgba(0, 0, 0, 0.2);
}
.counter__scroll-button__icon:after {
  height: 50px;
  background-image: url("../img/svg-sprite.svg");
  background-position: 0px -240px;
  width: 50px;
  background-size: 150px 472px;
  background-repeat: no-repeat;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.counter__scroll-button__icon::after {
  transform: translateY(-20px);
  transition: transform 0.1s ease;
  animation: bounce 1s ease infinite alternate;
}
.counter__scroll-button:hover .counter__scroll-button__icon::after {
  animation: none;
  transform: translateY(-24px);
}
.counter__scroll-button:active .counter__scroll-button__icon:after {
  animation: none;
  transform: translateY(-6px);
}

body.widget .counter__copy {
  font-size: 1.2rem;
  margin-bottom: 0;
}
@media all and (min-width: 33.75em) {
  body.widget .counter__copy {
    font-size: 1.6rem;
  }
}
.counter__item .counter__copy {
  margin-bottom: 0;
}
.is-fixed .counter__copy {
  font-size: 80%;
}

@keyframes bounce {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(-16px);
  }
}
.tabs {
  text-align: left;
}

.tabs__wrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -6rem;
  left: 15px;
  right: 15px;
  height: 6rem;
  transform: translateY(100%);
  transition: transform 0.7s ease;
}
body.area--main .tabs__wrapper {
  transform: translateY(0%);
}

.tabs__title {
  font-family: "Strada", Helvetica, Arial, sans-serif;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 18rem;
  background-color: #0080C9;
  font-size: 1.2rem;
  border-right: 1px solid #73C3E9;
}
@media all and (min-width: 33.75em) {
  .tabs__title {
    font-size: 1.6rem;
  }
}
.tabs__title.collapsed {
  background-color: #36A9E0;
}
.tabs__title:first-of-type {
  border-left: 1px solid #73C3E9;
}
.tabs__title:hover {
  background-color: #F0AE2E;
}
.tabs__title span {
  border: 1px solid blue;
}

.tabs__content {
  overflow: hidden;
  border-bottom: 1px solid #fff;
  transition: max-height 0.1s ease;
}
.tabs__content.collapsed {
  border-color: #36A9E0;
}

.accordion {
  position: relative;
}

.accordion__item {
  border-top: 1px solid #fff;
  position: relative;
}

.accordion__drawer {
  cursor: pointer;
  padding: 0 2rem;
  background-color: #36A9E0;
  position: relative;
}
.accordion__drawer:before {
  display: block;
  content: "";
  height: 0;
  position: absolute;
  width: 0;
  z-index: 10;
  border-left: 1.5rem solid transparent;
  border-right: 1.5rem solid transparent;
  border-top: 1.5rem solid #36A9E0;
  bottom: -1.5rem;
  left: calc(50% - 1.5rem);
  left: 2rem;
}
.accordion__drawer:before {
  transition: transform 0.7s ease;
}
.accordion__drawer:hover {
  background-color: #36A9E0;
}
.accordion__drawer.collapsed:hover {
  background-color: #F0AE2E;
}
.accordion__drawer.collapsed:before {
  transform: translateY(-100%);
  z-index: -1;
}

.accordion__title {
  position: relative;
  line-height: 2rem;
  padding: 1.2rem 3.2rem 1.2rem 0;
  margin-bottom: 0;
  color: #fff;
}
.accordion__title:after {
  height: 21px;
  background-image: url("../img/svg-sprite.svg");
  background-position: 0px -172px;
  width: 21px;
  background-size: 150px 472px;
  background-repeat: no-repeat;
  bottom: 0;
  content: "";
  display: block;
  left: auto;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}
@media all and (min-width: 33.75em) {
  .accordion__title {
    padding: 1.2rem 0 1.2rem 0;
  }
  .accordion__title:after {
    height: 21px;
    background-image: url("../img/svg-sprite.svg");
    background-position: 0px -172px;
    width: 21px;
    background-size: 150px 472px;
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    margin-top: -4px;
  }
}
@media all and (min-width: 33.75em) {
  .accordion__drawer.collapsed .accordion__title:after {
    height: 21px;
    background-image: url("../img/svg-sprite.svg");
    background-position: 0px -219px;
    width: 21px;
    background-size: 150px 472px;
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
}

.accordion__content {
  position: relative;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: #f0f0f0;
  color: #222;
}

.accordion__left {
  float: left;
  padding: 5rem 2rem 0 2rem;
  width: 100%;
}
@media all and (min-width: 33.75em) {
  .accordion__left {
    width: 70%;
    margin-bottom: 12rem;
  }
}

.accordion__right {
  float: left;
  padding: 1.2rem 2rem 5rem 2rem;
  width: 100%;
  margin-bottom: 6rem;
}
@media all and (min-width: 33.75em) {
  .accordion__right {
    padding: 5rem 2rem;
    width: 30%;
  }
}

.accordion__copy:last-of-type {
  margin-bottom: 0;
}

.accordion__link {
  display: inline-block;
  margin-right: 2rem;
}
.accordion__link--download {
  position: relative;
  display: block;
  color: #222;
  margin-bottom: 1.2rem;
  margin-right: 0;
  padding-left: 3.2rem;
  z-index: 1;
}
.accordion__link--download:before {
  height: 26px;
  background-image: url("../img/svg-sprite.svg");
  background-position: 0px -193px;
  width: 20px;
  background-size: 150px 472px;
  background-repeat: no-repeat;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  margin: auto;
  position: absolute;
  right: auto;
  top: 0;
}
.accordion__link--readmore {
  display: inline-block;
}
.accordion__link--readmore:active, .accordion__link--readmore:focus {
  text-decoration: none;
  color: #36A9E0;
}
.accordion__link--readmore:hover {
  text-decoration: underline;
  color: #F0AE2E;
}
.accordion__link--external {
  transform: translateY(7.2rem);
  transition: transform 0.3s ease;
  transition-delay: 0.3s;
}
.accordion__link--external.active {
  transform: translateY(0);
}

.accordion__bottom {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12rem;
  background-color: #f0f0f0;
  padding: 5rem 2rem;
  line-height: 5rem;
  transition: opacity 0.3s ease;
}

.compare:nth-of-type(even) {
  background: #e2e2e2;
}

.compare__wrapper {
  max-width: 500px;
  margin: 2rem auto;
}

.compare__image-container {
  margin: 0;
  width: 60%;
}
@media all and (min-width: 33.75em) {
  .compare__image-container {
    width: 50%;
  }
}
.compare__image-container img {
  margin: 0 auto;
}
@media all and (min-width: 33.75em) {
  .compare__image-container img {
    margin: 0 2rem;
  }
}
.compare:nth-of-type(even) .compare__image-container {
  float: left;
}
.compare:nth-of-type(even) .compare__image-container img {
  float: right;
}
.compare:nth-of-type(odd) .compare__image-container {
  float: right;
}
.compare:nth-of-type(odd) .compare__image-container img {
  float: left;
}

.compare__caption {
  position: relative;
  clear: both;
  width: 100%;
  padding: 1.2rem;
}
.compare__caption:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: linear-gradient(90deg, transparent, #222, #222, transparent);
}
.compare:nth-of-type(even) .compare__caption {
  text-align: right;
}
.compare:nth-of-type(odd) .compare__caption {
  text-align: left;
}

.compare__amount {
  display: block;
  padding: 6rem 2rem;
  width: 40%;
  font-size: 2rem;
}
@media all and (min-width: 33.75em) {
  .compare__amount {
    width: 50%;
    font-size: 3rem;
  }
}
.compare:nth-of-type(even) .compare__amount {
  float: left;
  text-align: left;
}
.compare:nth-of-type(odd) .compare__amount {
  float: right;
  text-align: right;
}

@keyframes spin-low {
  0% {
    background-color: #36A9E0;
    transform: perspective(600px) translateZ(29px) rotateX(0deg);
  }
  3.33% {
    background-color: #fff;
    transform: perspective(600px) translateZ(29px) rotateX(120deg);
  }
  33.333333% {
    background-color: #fff;
    transform: perspective(600px) translateZ(29px) rotateX(120deg);
  }
  36.666666% {
    background-color: #222;
    transform: perspective(600px) translateZ(29px) rotateX(240deg);
  }
  66.666666% {
    background-color: #222;
    transform: perspective(600px) translateZ(29px) rotateX(240deg);
  }
  69.999999% {
    background-color: #36A9E0;
    transform: perspective(600px) translateZ(29px) rotateX(360deg);
  }
  100% {
    background-color: #36A9E0;
    transform: perspective(600px) translateZ(29px) rotateX(360deg);
  }
}
@keyframes spin {
  0% {
    background-color: #36A9E0;
    transform: perspective(600px) translateZ(34px) rotateX(0deg);
  }
  3.33% {
    background-color: #fff;
    transform: perspective(600px) translateZ(34px) rotateX(120deg);
  }
  33.333333% {
    background-color: #fff;
    transform: perspective(600px) translateZ(34px) rotateX(120deg);
  }
  36.666666% {
    background-color: #222;
    transform: perspective(600px) translateZ(34px) rotateX(240deg);
  }
  66.666666% {
    background-color: #222;
    transform: perspective(600px) translateZ(34px) rotateX(240deg);
  }
  69.999999% {
    background-color: #36A9E0;
    transform: perspective(600px) translateZ(34px) rotateX(360deg);
  }
  100% {
    background-color: #36A9E0;
    transform: perspective(600px) translateZ(34px) rotateX(360deg);
  }
}
.display {
  position: relative;
  height: 100px;
  width: calc(100% - 24px);
  margin: 0 12px;
}
@media (min-height: 350px) {
  .display {
    height: 120px;
  }
}
@media all and (min-width: 33.75em) {
  .display {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
}

.display__element {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transform: scale(0.89);
}

.display__flex {
  width: 100%;
}

.display__side {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #36A9E0;
  font-size: 1.6rem;
  color: #fff;
  text-align: center;
  backface-visibility: hidden;
  transform-origin: 50% 50% -29px;
}
@media (min-height: 350px) {
  .display__side {
    transform-origin: 50% 50% -34px;
  }
}
.mouseover .display__side {
  animation: none;
}
.display__side--1 {
  animation: spin-low 15s infinite linear;
  animation-delay: -15s;
  transform: perspective(600px) translateZ(29px) rotateX(0deg);
}
@media (min-height: 350px) {
  .display__side--1 {
    animation-name: spin;
    transform: perspective(600px) translateZ(34px) rotateX(0deg);
  }
}
.display__side--2 {
  animation: spin-low 15s infinite linear;
  animation-delay: -10s;
  transform: perspective(600px) translateZ(29px) rotateX(120deg);
}
@media (min-height: 350px) {
  .display__side--2 {
    animation-name: spin;
    transform: perspective(600px) translateZ(34px) rotateX(120deg);
  }
}
.display__side--3 {
  animation: spin-low 15s infinite linear;
  animation-delay: -5s;
  transform: perspective(600px) translateZ(29px) rotateX(240deg);
}
@media (min-height: 350px) {
  .display__side--3 {
    animation-name: spin;
    transform: perspective(600px) translateZ(34px) rotateX(240deg);
  }
}

.footer-meta-nav > ul {
  display: inline-block;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  margin: 8rem 0 5rem;
}
.footer-meta-nav > ul li {
  display: inline-block;
}
@media all and (min-width: 46.25em) {
  .footer-meta-nav > ul {
    margin: 5rem 0;
  }
}
.footer-meta-nav li:not(:last-of-type) {
  margin-right: 1.2rem;
}
.footer-meta-nav a {
  color: #AAA;
}

.csc-textpic {
  clear: both;
  margin-bottom: 5rem;
}
@media all and (min-width: 46.25em) {
  .csc-textpic {
    display: flex;
    margin-bottom: 8rem;
  }
}
.csc-textpic .bodytext:last-child {
  margin-bottom: 0;
}
@media all and (min-width: 46.25em) {
  .csc-textpic .bodytext {
    line-height: 1.6;
  }
}
.csc-textpic .csc-textpic-text {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s 0s ease;
}
@media all and (min-width: 46.25em) {
  .csc-textpic .csc-textpic-text {
    height: auto;
    opacity: 1;
    transition: none;
  }
}
.csc-textpic.textpic-accordion.is-active .csc-textpic-text {
  height: auto;
  opacity: 1;
}

@media all and (min-width: 46.25em) {
  .csc-textpic-intext-left-nowrap .csc-textpic-imagewrap {
    padding-right: 2rem;
  }
}

@media all and (min-width: 46.25em) {
  .csc-textpic-intext-right-nowrap .csc-textpic-imagewrap {
    padding-left: 2rem;
  }
}

.csc-textpic-imagewrap {
  text-align: center;
}
@media all and (min-width: 46.25em) {
  .csc-textpic-imagewrap {
    flex: 0 0 30%;
    align-self: center;
  }
}
@media all and (min-width: 46.25em) {
  .csc-textpic-intext-right-nowrap .csc-textpic-imagewrap {
    order: 1;
  }
}

.csc-textpic-image {
  margin-bottom: 2rem;
}
.textpic-accordion .csc-textpic-image {
  margin-bottom: 0;
}
@media all and (min-width: 46.25em) {
  .csc-textpic-image {
    margin-bottom: 0;
  }
}
@media all and (min-width: 46.25em) {
  .csc-textpic-text {
    flex: 1 1 auto;
  }
}
@media all and (min-width: 46.25em) {
  .csc-textpic-imagewrap + .csc-textpic-text {
    flex-basis: 70%;
  }
}

.csc-textpicHeader {
  text-align: center;
}
@media all and (min-width: 46.25em) {
  .csc-textpicHeader {
    text-align: left;
  }
}
.textpic-accordion .csc-textpic-imagewrap {
  position: relative;
}

.textpic-accordion__toggle {
  background: rgba(54, 169, 224, 0.9);
  display: block;
  cursor: pointer;
  font-size: 1em;
  border: 0;
  overflow: hidden;
  text-indent: 500%;
  white-space: nowrap;
  border-radius: 50%;
  box-shadow: 0 0 3px 0 rgba(170, 170, 170, 0.3);
  height: 50px;
  left: -40px;
  margin: -2rem auto 2rem;
  outline: 0;
  padding: 0;
  position: relative;
  transform-origin: 50%;
  transition: transform 0.1s 0s ease;
  width: 50px;
  z-index: 42;
}
.csc-default:nth-child(even) .textpic-accordion__toggle {
  left: 40px;
}
.textpic-accordion__toggle:focus, .textpic-accordion__toggle:hover {
  transform: scale(1.1);
}
.textpic-accordion__toggle::before {
  bottom: 0;
  color: #fff;
  content: "+";
  display: block;
  font-size: 40px;
  left: 0;
  line-height: 42px;
  margin: auto;
  position: absolute;
  right: 0;
  text-indent: 0;
  top: 0;
}
.is-active .textpic-accordion__toggle::before {
  content: "-";
}
@media all and (min-width: 46.25em) {
  .textpic-accordion__toggle {
    display: none;
  }
}

.media-container {
  margin-bottom: 2rem;
}

.media-container--center {
  text-align: center;
}

.media-container--left {
  text-align: left;
}
@media all and (min-width: 33.75em) {
  .media-container--left.media-container--intext {
    float: left;
    margin-right: 2rem;
  }
}

.media-container--right {
  text-align: right;
}
@media all and (min-width: 33.75em) {
  .media-container--right.media-container--intext {
    float: right;
    margin-left: 2rem;
    margin-right: 1.2rem;
  }
}

.media-container--beside {
  margin-bottom: 2rem;
}
@media all and (min-width: 46.25em) {
  .media-container--beside {
    margin-bottom: 0;
  }
}
@media all and (min-width: 46.25em) {
  .media-container--beside.media-container--left {
    padding-right: 2rem;
  }
}
@media all and (min-width: 46.25em) {
  .media-container--beside.media-container--left ~ * {
    padding-left: 2rem;
  }
}
@media all and (min-width: 46.25em) {
  .media-container--beside.media-container--right {
    order: 2;
    padding-left: 2rem;
  }
}
@media all and (min-width: 46.25em) {
  .media-container--beside.media-container--right ~ * {
    padding-right: 2rem;
  }
}

.media-container--intext {
  margin-top: 0.2em;
}

.co--textmedia {
  clear: both;
  margin-bottom: 5rem;
}
@media all and (min-width: 46.25em) {
  .co--textmedia {
    margin-bottom: 8rem;
  }
}
@media all and (min-width: 46.25em) {
  .co--textmedia.media--beside.media--left .co__header {
    margin-left: calc(25% + 20px);
  }
}
@media all and (min-width: 46.25em) {
  .co--textmedia.media--beside.media--right .co__header {
    margin-right: calc(25% + 20px);
  }
}
.co--textmedia.textmedia-accordion.is-active .co__main p {
  height: auto;
  opacity: 1;
}
.co--textmedia:nth-child(even) .textmedia-accordion__toggle {
  left: 40px;
}
.co--textmedia .co__header {
  display: none;
  text-align: center;
}
@media all and (min-width: 46.25em) {
  .co--textmedia .co__header {
    display: block;
    text-align: left;
  }
}
.co--textmedia .co__header.clone {
  display: block;
}
@media all and (min-width: 46.25em) {
  .co--textmedia .co__header.clone {
    display: none;
  }
}
.co--textmedia .co__main {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
}
@media all and (min-width: 46.25em) {
  .co--textmedia .co__main {
    align-items: center;
    flex-direction: row;
  }
}
.co--textmedia .co__main > p {
  height: 0;
  margin-bottom: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s 0s ease;
}
@media all and (min-width: 46.25em) {
  .co--textmedia .co__main > p {
    height: auto;
    line-height: 1.6;
    opacity: 1;
    transition: none;
  }
}
.co--textmedia .media-container {
  text-align: center;
}
@media all and (min-width: 46.25em) {
  .co--textmedia .media-container {
    flex: 0 0 25%;
  }
}
.co--textmedia .figure {
  margin-bottom: 2rem;
}
.textpic-accordion .co--textmedia .figure {
  margin-bottom: 0;
}
@media all and (min-width: 46.25em) {
  .co--textmedia .figure {
    margin-bottom: 0;
  }
}
.co--textmedia .textmedia-accordion .media-container {
  position: relative;
}
.co--textmedia.is-active .textmedia-accordion__toggle::before {
  content: "-";
}
.co--textmedia .textmedia-accordion__toggle {
  background: rgba(54, 169, 224, 0.9);
  display: block;
  cursor: pointer;
  font-size: 1em;
  border: 0;
  overflow: hidden;
  text-indent: 500%;
  white-space: nowrap;
  border-radius: 50%;
  box-shadow: 0 0 3px 0 rgba(170, 170, 170, 0.3);
  height: 50px;
  left: -40px;
  margin: -2rem auto 2rem;
  outline: 0;
  padding: 0;
  position: relative;
  transform-origin: 50%;
  transition: transform 0.1s 0s ease;
  width: 50px;
  z-index: 42;
}
.co--textmedia .textmedia-accordion__toggle:focus, .co--textmedia .textmedia-accordion__toggle:hover {
  transform: scale(1.1);
}
.co--textmedia .textmedia-accordion__toggle::before {
  bottom: 0;
  color: #fff;
  content: "+";
  display: block;
  font-size: 40px;
  left: 0;
  line-height: 42px;
  margin: auto;
  position: absolute;
  right: 0;
  text-indent: 0;
  top: 0;
}
@media all and (min-width: 46.25em) {
  .co--textmedia .textmedia-accordion__toggle {
    display: none;
  }
}

.to-info {
  color: inherit;
}
.to-info:focus, .to-info:hover {
  color: inherit;
}

.document-section__info {
  font-size: 1.4rem;
  color: #888;
  padding-bottom: 6rem;
}
@media all and (min-width: 46.25em) {
  .document-section__info {
    margin: 0 auto;
    max-width: 700px;
  }
}

.cta-link {
  position: relative;
  cursor: pointer;
  display: block;
  background: #F0AE2E;
  width: 150px;
  text-align: center;
  color: #fff;
  line-height: 5rem;
  margin: 1rem auto 0;
}
@media (min-height: 350px) {
  .cta-link {
    margin: 3rem auto 0;
  }
}
.cta-link:focus, .cta-link:hover {
  background-color: #9dc530;
}

.cta-link__text {
  color: #fff;
}
.cta-link__text:focus, .cta-link__text:hover {
  color: #fff;
}

.cta-link__icon {
  position: relative;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 5rem;
  transition: width 0.1s ease, right 0.1s ease;
}
.cta-link__icon:before {
  height: 50px;
  background-image: url("../img/svg-sprite.svg");
  background-position: 0px -240px;
  width: 50px;
  background-size: 150px 472px;
  background-repeat: no-repeat;
  bottom: 0;
  content: "";
  display: block;
  left: auto;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(-90deg);
  z-index: -1;
}
.cta-link:focus .cta-link__icon, .cta-link:hover .cta-link__icon {
  width: 3rem;
  right: -3rem;
}

.fp-component {
  background-color: #cee;
  color: #222;
  display: block;
  margin: 2rem 0;
  padding: 2rem;
  border: 1px solid #9edfdf;
}
.fp-component:before, .fp-component:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
.fp-component:after {
  clear: both;
}
.document-header .fp-component {
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  margin: 0;
}
.fp-component .document-section__title {
  display: inline-block;
  margin-bottom: 0;
}
.fp-component .teaser-list {
  margin: 0;
}
.fp-component .navigation-breadcrumb {
  padding: 0;
}
.fp-component .content__share {
  border: 0;
  margin: 0;
  padding: 0;
}
.fp-component p, .fp-component figure, .fp-component blockquote, .fp-component figcaption, .fp-component pre, .fp-component ul, .fp-component ol, .fp-component dl {
  margin: 0;
}

.fp-component__label {
  display: block;
}
.document-section__title + .fp-component__label {
  display: inline-block;
  margin-left: 1em;
}
.document-section__title + .fp-component__label:before {
  content: "(";
}
.document-section__title + .fp-component__label:after {
  content: ")";
}

.fp-component__list {
  margin-bottom: 0;
  list-style: square;
}
.fp-status--wip {
  outline: 1px dashed #FF0064 !important;
}